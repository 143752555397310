@import "~@ng-select/ng-select/themes/default.theme.css";

body {
  font-family: var(--font-family-base) !important;
  color: var(--slate-900);
  background-color: var(--grey-000);
}

.cdk-drag-preview {
	list-style-type: none;
	box-sizing: border-box;
	border-radius: 4px;
	box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
				0 8px 10px 1px rgba(0, 0, 0, 0.14),
				0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
	opacity: 0;
}

.cdk-drag-animating {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-item.cdk-drop-list-dragging .drag-item:not(.cdk-drag-placeholder) {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.draggable {
	cursor: move; /* fallback if grab cursor is unsupported */
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;

	&:active {
		cursor: grabbing;
		cursor: -moz-grabbing;
		cursor: -webkit-grabbing;
	}

	.i {
		cursor: move; /* fallback if grab cursor is unsupported */
		cursor: grab;
		cursor: -moz-grab;
		cursor: -webkit-grab;

		&:active {
			cursor: grabbing;
			cursor: -moz-grabbing;
			cursor: -webkit-grabbing;
		}
	}
}

.bg-light-blue {
  background-color: var(--slate-100);
}

.border-blue {
  border: 2px solid var(--blue-700);
}

.blue-bg {
	background-color: var(--blue-700);
}

#page-container {
  position: relative;
  min-height: calc(100vh - 215px);
}

#content-container {
  padding: 25px;
  padding-bottom: 145px;
}

#footer {
  background-color: var(--blue-900);
  color: white;
  bottom: 0;
  left: 0;
  padding-bottom: 15px;
  padding-top: 15px;
  position: absolute;
  width: 100%;
}

.form-group {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 4px;
  -moz-box-shadow: 0px 1px 4px 0px #d9d9d9;
  -webkit-box-shadow: 0px 1px 4px 0px #d9d9d9;
  box-shadow: 0px 1px 4px 0px #d9d9d9;
  margin-bottom: 15px;
}

.disney-material {
  background-color: #ffffff;
  margin: 15px 0px;
  border-radius: 4px;
  -moz-box-shadow: 0px 1px 4px 0px #d9d9d9;
  -webkit-box-shadow: 0px 1px 4px 0px #d9d9d9;
  box-shadow: 0px 1px 4px 0px #d9d9d9;
}

.disney-material-body {
  padding: 20px;
}

.disney-material-header {
  background-color: var(--slate-200);
  padding: 10px 20px;
  border-bottom: 2px solid var(--slate-300);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.point-hover:hover {
  cursor: pointer;
}

.form-check-label {
	margin-top: 3px;
	margin-left: 5px;
}

.form-check-input {
	background-color: var(--grey-400);
	width: 20px;
	height: 20px;

	&::after {
		padding: 5px;
	}

	&:checked {
		background-color: var(--slate-800);
		&::after {
			background-color: white;
		}
	}

	&:hover {
		cursor: pointer;
	}
}

.primary-bg { background-color: var(--blue-800); }
